<script setup lang="ts">
import type { GqlError } from "nuxt-graphql-client";
import { KsIcon } from "@aschehoug/kloss";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

defineProps<{ error: Error | GqlError }>();

const show = ref(true);

function close() {
  show.value = false;
}

setTimeout(() => {
  show.value = false;
}, 15000);
</script>

<template>
  <section v-if="show" class="error min-h-12 w-screen bg-red-30 text-[#FEE0E3]">
    <p class="mx-auto flex max-w-content items-center justify-between p-4">
      En feil har oppstått:
      <em>{{ !!error && "message" in error ? error?.message : error }}</em>
      <button class="error__close mr-8 text-2xl" @click="close">
        <KsIcon :icon="faXmark" />
      </button>
    </p>
  </section>
</template>
